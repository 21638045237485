import api from "./api";

const getPageByIdentifier = async (identifier, params) => {
  params = params ? params : {};
  params.fields = "*";
  params["filter[identifier._eq]"] = identifier;

  return await api.get(`items/static_pages${api.addParams(params)}`);
};

const static_pages = {
  getPageByIdentifier,
};

export default static_pages;
