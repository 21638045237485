<template>
  <div class="page-content">
    <!-- inner page banner -->
    <div
      class="dez-bnr-inr overlay-black-middle"
      :style="`background-image: url(${getImage(
        '39655d13-26d4-42fe-b554-82d20864ea9a',
        'x2000y1200'
      )})`"
    >
      <div class="container">
        <div class="dez-bnr-inr-entry">
          <h1 class="text-white">Über Uns</h1>
        </div>
      </div>
    </div>
    <!-- inner page banner END -->
    <!-- Breadcrumb row -->
    <Breadcrumb v-bind:data="[{ label: 'Über Uns' }]" />
    <!-- Breadcrumb row END -->
    <div class="section-full content-inner-1">
      <div class="container" v-html="content"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent, inject } from "vue";
import { getImage } from "../lib/helper";
import static_pages from "../lib/api/static_pages";
import Breadcrumb from "../components/misc/Breadcrumb.vue";
export default defineComponent({
  components: {
    Breadcrumb,
  },
  methods: {
    getImage,
  },
  data() {
    return {
      content: "",
    };
  },
  async mounted() {
    const staticPagesResult = await static_pages.getPageByIdentifier(
      "about_us"
    );
    this.content = staticPagesResult.data.shift().content;
    console.log(this.content);
  },
  created() {
    this.section_settings = inject("section_settings");
  },
});
</script>
